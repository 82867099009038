import { environment } from '../../environments/environment';

export class AppSetting {
   public static API_ENDPOINT = environment.apiUrl;
   public static API_CHALLENGE = environment.challengeUrl;
   public static API_VIRTUAL_PET = environment.virtualPetUrl;
   public static VIRTUALPET_API_KEY = environment.virtualPetApiKey;
   public static STATIC_FILES_ENDPOINT = 'https://static.storybook-app.com';
   public static STATIC_FILES_GC_ENDPOINT = 'https://storage.googleapis.com';
   public static STATIC_FILES_GC_ENDPOINT_NEW = 'https://storage.googleapis.com/storybook_storage';
   public static TRANSLATION_PROJECT = environment.translationProject;
   public static CHALLENGES_PROJECT = environment.challengesProject;
   public static VIRTUALPET_PROJECT = environment.virtualPetProject;
   public static TOKEN_AUTHENTICATION = environment.tokenAuthentication;
   public static TRANSLATION_URL = 'https://weblate.storybook-app.com/translate/storybook/';
   public static get TRANSLATION_SEARCH_URL(): string {
      return `${AppSetting.TRANSLATION_URL}${AppSetting.TRANSLATION_PROJECT}/en/?q=context:=`;
   }
   public static get CHALLENGES_SEARCH_URL(): string {
      return `${AppSetting.TRANSLATION_URL}${AppSetting.CHALLENGES_PROJECT}/en/?q=context:=`;
   }
   public static get VIRTUAL_PET_SEARCH_URL(): string {
      return `${AppSetting.TRANSLATION_URL}${AppSetting.VIRTUALPET_PROJECT}/en/?q=context:=`;
   }
}


