// multi-drag-drop-container.component.ts
import { Component, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'ngx-multi-drag-drop-container',
  templateUrl: './multi-drag-drop-container.component.html',
  styleUrls: ['./multi-drag-drop-container.component.scss'],
})
export class MultiDragDropContainerComponent {
  @Input() leftConfig: any[] = [];
  @Input() rightConfig: any[] = [];

  drop(event: CdkDragDrop<any[]>, index: number, isLeft: boolean) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    const configToUpdate = isLeft ? this.leftConfig[index] : this.rightConfig[index];
    if (configToUpdate) {
      configToUpdate.availableData = [...configToUpdate.availableData];
      configToUpdate.activeData = [...configToUpdate.activeData];
      configToUpdate.handleItemsDropped(event);
      configToUpdate.handleActiveDataProperty(event);
    } else {
      console.warn('⚠️ No se encontró la configuración para actualizar.');
    }
  }
}
